import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Themes from "../components/sections/themes"
import Footer from "../components/sections/footer"
import Connect from "../components/sections/connect"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <Header />
    <Features />
    <Themes />
    <Connect />
    <Footer />
  </Layout>
)

export default IndexPage
